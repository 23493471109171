
    import * as _0 from "../../../node_modules/@pidoc/components/src/index.ts"
import * as _1 from "piral-docs-tools/components"
import * as _2 from "../../../node_modules/tslib/tslib.es6.js"
import * as _3 from "../../../node_modules/react/index.js"
import * as _4 from "../../../node_modules/react-dom/index.js"
import * as _5 from "../../../node_modules/react-router/esm/react-router.js"
import * as _6 from "../../../node_modules/react-router-dom/esm/react-router-dom.js"
import { DefaultErrorInfo } from 'piral-core/lib/defaults/DefaultErrorInfo.js';
import { DefaultLoadingIndicator } from 'piral-core/lib/defaults/DefaultLoadingIndicator.js';
import { DefaultLayout } from 'piral-core/lib/defaults/DefaultLayout.js';
import { DefaultRouter } from 'piral-core/lib/defaults/DefaultRouter_v5.js';
import { DefaultRouteSwitch } from 'piral-core/lib/defaults/DefaultRouteSwitch_v5.js';
import { createRedirect, createNavigation, useCurrentNavigation } from 'piral-core/lib/defaults/navigator_v5.js'
import { useGlobalStateContext } from 'piral-core/lib/hooks/globalState.js';
    
        export function applyStyle(element) {
          element.style.display = 'contents';
        }
      

    export function fillDependencies(deps) {
      deps['@pidoc/core']={};deps["@pidoc/components"]=_0;deps["@pidoc/components@0.18.4"]=_0;deps["piral-docs-tools/components"]=_1;deps["tslib"]=_2;deps["tslib@2.5.2"]=_2;deps["react"]=_3;deps["react@18.2.0"]=_3;deps["react-dom"]=_4;deps["react-dom@18.2.0"]=_4;deps["react-router"]=_5;deps["react-router@5.3.4"]=_5;deps["react-router-dom"]=_6;deps["react-router-dom@5.3.4"]=_6
    }
  

    export { createRedirect, createNavigation };
  

    export const publicPath = "/";
  

    export function createDefaultState() {
      return {
        app: {
          error: undefined,
          loading: typeof window !== 'undefined',
          wrap: false,
        },
        components: {
          ErrorInfo: DefaultErrorInfo,
          LoadingIndicator: DefaultLoadingIndicator,
          Router: DefaultRouter,
          RouteSwitch: DefaultRouteSwitch,
          Layout: DefaultLayout,
        },
        errorComponents: {},
        registry: {
          extensions: {},
          pages: {},
          wrappers: {},
        },
        routes: {},
        data: {},
        portals: {},
        modules: [],
      };
    }
  
export function integrateDebugger() {}
export function integrateEmulator() {}

    export function useRouteFilter(paths) {
      
    useCurrentNavigation();
  
return paths;
    }
  
  